import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '@styles/global'
import Button from '@components/Button'
import Image from '@components/Image'
import resolveLink from '@utils/resolveLink'
import SanityLink from '@components/SanityLink'
import BlockContent from '@sanity/block-content-to-react'
import { stringify } from 'postcss'

const Tile = ({ className, content, hideText }) => {
	const [hover, setHover] = useState(false)

	return (
		<Wrap 
			className={className} 
			link={{document: content, linkType: 'internal'}}
		>
			<div 	
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			>
				<ImageContainer>
					{(content.header?.image || content?.headerImage) ? 
						<MainImage 
							image={content.header?.image || content?.headerImage}
							aspectRatio={1.09}
						/> : <Spacer/>
					}
					
					<Border 
						image={content.header?.image || content?.headerImage}
						css={css`
							opacity: ${hover ? '1' : '0'};
						`}
					/>
				</ImageContainer>
				<Title>{content?.title}</Title>
				{!hideText &&
					<Text>
						<BlockContent blocks={content.header?.text} />
					</Text>
				}
				<Button underline={hover} icon='Rarr' flex>Read More</Button>
			</div>
		</Wrap>
	)
}

const Wrap = styled(SanityLink)`
	display: block;
	margin-bottom: 3px;
`
const MainImage = styled(Image)`
	margin-bottom: var(--s);
	box-sizing: border-box;
`
const Title = styled.h6`
	margin-bottom: var(--m);
`
const Text = styled.div`
	margin-bottom: var(--s);
`
const ImageContainer = styled.div`
	position: relative;
`
const Border = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	border: solid 6px;
	border-color: var(--bteal);
	transition: opacity 0.3s;
	display: ${props => props.image ? 'block' : 'none'};
`

const Spacer = styled.div`
	width: 100%;
	padding-bottom: 91.743%;
	margin-bottom: var(--s);
`
Tile.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default Tile