import Link from 'next/link'
import PropTypes from 'prop-types'

const TestComponent = ({ children }) => {
	return (
		<div>
			<Link href='/'>Home</Link>
			<Link href='/contact'>Contact</Link>
			<div>
				{children}
			</div>
		</div>
	)
}

TestComponent.propTypes = {
}

export default TestComponent