import PropTypes from 'prop-types'
import Header from '@components/Header'
import { useSiteState } from '@context/siteContext'
import Alert from './alert'
import Script from 'next/script'
import { gTag } from '@utils/constants'
import { useEffect } from 'react'
import { css, Global } from '@emotion/react'
import GlobalStyles, { maxMenu } from '@styles/global'
import Footer from '@components/Footer'
import smoothscroll from 'smoothscroll-polyfill'
import { mobile } from '@styles/global'
import styled from '@emotion/styled'
import Button from '@components/Button'
import PopUp from '@components/PopUp'
import Hotjar from '@hotjar/browser'
import { useRouter } from 'next/router'

const siteId = 3659613
const hotjarVersion = 6

const SiteLayout = ({ children, className }) => {

	const [siteState, setSiteState] = useSiteState()
	// Hotjar.init(siteId, hotjarVersion)

	const router = useRouter()
  
	useEffect(() => {
		smoothscroll.polyfill()
	}, [])

	// // Close the cart whenever the route changes
	// useEffect(() => {
	// 	closeCart()
	// 	// eslint-disable-next-line
  // }, [router?.asPath])

	return (
		<div className={className}>
			<GlobalStyles />
			{siteState.popUp && 
				<Global styles={css`
					html, body {
						overflow: hidden;
					}
				`}/>
			}
			{siteState?.preview && <Alert />}
			<div className="header">
				<Header />
			</div>
			{gTag &&
			<>
				<Script
					src={`https://www.googletagmanager.com/gtag/js?id=${gTag}`}
					strategy="afterInteractive"
				/>
				<Script id="google-analytics" strategy="afterInteractive">
					{`
					window.dataLayer = window.dataLayer || [];
					function gtag(){window.dataLayer.push(arguments);}
					gtag('js', new Date());

					gtag('config', '${gTag}');
				`}
				</Script>
			</>
			}
			<Main>
				<div css={css`
					min-height: ${router?.asPath === '/chat' ? 0 : 'calc(100vh)'};
					display: flex;
					flex-direction: column;
					${mobile}{
						min-height: ${router?.asPath === '/chat' ? 0 : 'calc(100vh)'};
					}
				`}>
					{children}
					{router?.asPath !== '/chat' && <Footer css={css`margin-top: auto;`}/>}
				</div>
			</Main>
			{!router?.asPath?.includes('/checklists') &&
				<QuickExit 
					rel='noreferrer' 
					link={{
						linkType: 'external', 
						url: 'https://google.com',
					}}
					bgColor='orange'
					icon='BdrCross'
					className='cap'
				>
					Quick Exit Site
				</QuickExit>
			}
			<PopUp />	
			<Overlay 
				onClick={() => setSiteState(prevState => ({
					...prevState,
					popUp: false,
				}))}
				show={siteState.popUp}
			/>
		</div>
	)
}

const Main = styled.main`
	margin-top: 100px;
	${maxMenu}{
		margin-top: 80px;
	}
`
const Overlay = styled.button`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
	background-color: var(--white);
	pointer-events: ${props => props.show ? 'all' : 'none'};
	opacity: ${props => props.show ? '0.5' : '0'};
	z-index: 111;
	transition: opacity 0.3s;
`
const QuickExit = styled(Button)`
  position: fixed;
  right: 0;
	z-index: 112;
  bottom: var(--xxxxl);
	color: inherit;
	transform: rotate(90deg);
	transform-origin: 76% 76%;
  min-width: 190px;
	:hover {
		color: inherit;
	}
	${mobile}{
		min-width: 190px;
	}
`

SiteLayout.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
}

export default SiteLayout