import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { mobile, tablet, desktop } from '@styles/global'

const Section = ({ children, className, id, fullWidth }) =>
	<Wrap className={className} id={id}>
		<Container fullWidth={fullWidth}>
			{children}
		</Container>
		{/* <Visible /> */}
	</Wrap>

Section.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	id: PropTypes.string,
	fullWidth: PropTypes.bool,
}

// const Visible = () => {
// 	const isDev = process.env.NODE_ENV === 'development' && !process.env.GATSBY_PREVIEW
// 	const [visible, setVisible] = useState(false)

// 	useEffect(() => {
// 		const handleKeydown = e => 
// 			e.code === 'KeyG' && setVisible(prevVis => !prevVis)
      
// 		isDev && document.addEventListener('keydown', handleKeydown)
// 		return isDev && document.addEventListener('keydown', handleKeydown)
// 	}, [isDev])

// 	if (!isDev || !visible) return null 

// 	return (
// 		<VisibleWrap>
// 			{[...(Array(12))].map((c, i) => <Cell key={i}/>)}
// 		</VisibleWrap>
// 	)
// }

const Wrap = styled.div`
  position: relative;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 30px;
  padding: 0 100px;
	box-sizing: content-box;
	max-width: ${props => props.fullWidth ? 'none' : '1400px'};
	margin: 0 auto;
	${desktop}{
		padding: 0 60px;
	}
	${tablet}{
		padding: 0 50px;
	}
  ${mobile}{
    grid-column-gap: 14px;
    padding: 0 30px;
  }
`

// const VisibleWrap = styled(Container)`
//   position: absolute;
//   width: 100%;
//   top: 0;
//   bottom: 0;
//   box-sizing: border-box;
//   z-index: 9999;
//   pointer-events: none;
// `

// const Cell = styled.div`
//   background: grey;
//   opacity: 0.1;
//   grid-column: span 1;
//   height: 100%;
// `

export default Section
