import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '@styles/global'
import RichText from '@components/RichText'
import { Triangle } from '@components/Svg'

const Quote = ({ className, quote, attribution, light }) => {
	return (
		<Wrap className={className} light={light}>
			<Message>
				{typeof quote === 'string' ? 
					'“' + quote + '”'
					:
					<RichText content={quote}/>
				}
			</Message>
			<Attribution>{attribution}</Attribution>
			<Tip light={light} />
		</Wrap>
	)
}

const Wrap = styled.div`
	position: relative;
	background-color: ${props => props.light ? 'var(--bteal40)' : 'var(--teal)'};
	color: ${props => props.light ? 'inherit' : 'var(--white)'};
	padding: var(--l);
	min-height: 295px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: var(--m);
	margin-bottom: var(--xxxl);
	${mobile}{
		min-height: 100px;
		padding: var(--s);
	}
`
const Message = styled.h4`
	margin-bottom: var(--l);
`
const Attribution = styled.div`
	
`
const Tip = styled(Triangle)`
	width: 55px;
	color: var(--teal);
	position: absolute;
	top: 100%;
	left: 50px;
	color: ${props => props.light ? 'var(--bteal40)' : 'var(--teal)'};
	${mobile}{
		width: 64px;
		left: 20px;
	}
`
Quote.propTypes = {
	className: PropTypes.string,
	quote: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.string
	]),
	attribution: PropTypes.string,
	light: PropTypes.bool,
}

export default Quote