import React from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence, motion } from 'framer-motion'

const Transition = ({children, elementKey, className, duration}) => {

	return (
		<AnimatePresence exitBeforeEnter>
			<motion.div 
				key={elementKey}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: duration ?? 0.6}}
				className={className}
			>
				{children}
			</motion.div>
		</AnimatePresence>
	)
}

Transition.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	elementKey: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.string,
	]),
	duration: PropTypes.number,
}

export default Transition